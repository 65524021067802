import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {

  const [scrollNav, setScrollNav] = useState(false);
  const stickyNav = () => {
    if (window.scrollY >= 56) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }
  window.addEventListener("scroll", stickyNav);
  return (
    // bg-[#012740]
    <section className="bg-[#287084] w-full">
      <div className="xl:container xl:mx-auto">
        {/* <div className="xl:block hidden">
          <div className=" grid lg:grid-cols-2 grid-cols-1 items-center" style={{ borderBottom: '2px solid rgb(255 255 255 / 8%)' }}>
            <div className="flex items-center">
              <i class="fa-solid fa-thumbtack text-[14px] leading-[46px] text-[#cca500] mr-2"></i>
              <p className='text-[#ffffff99] text-[14px] font-normal leading-[50px]'> Your Trusted Manufacturer and Exporter</p>
            </div>
            <div className="flex items-center justify-end">
              <i class="fa-regular fa-clock text-[14px] leading-[46px] text-[#cca500] mr-2"></i>
              <p className='text-[#ffffff99] text-[14px] font-normal leading-[24px] pr-3' style={{ borderRight: '1px solid rgb(255 255 255 / 8%)' }}> Always Open</p>
              <p className='text-[#ffffff99] text-[14px] font-normal leading-[24px] ml-3'> Follow us:</p>
              <ul className='flex'>
                <li className='mx-2'><a target='blank' href="https://www.linkedin.com/in/nalamsourcingbd-bangladesh-06377b270/"><i className="fa-brands fa-linkedin-in"></i></a></li>
                <li className='mx-2'><a href="/"><i className="fa-brands fa-instagram"></i></a></li>
                <li className='mx-2'><a href="/"><i className="fa-brands fa-facebook-f"></i></a></li>
                <li className='mx-2'><a href="/"><i className="fa-brands fa-twitter"></i></a></li>
                <li className='mx-2'><a href="/"><i className="fa-brands fa-pinterest-p"></i></a></li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className={`${scrollNav ? 'fixed top-0 left-0 ' : ''}  px-5 bg-[#287084] z-[999999999999999] w-full`}>
          <div className={`navbar ${scrollNav ? 'xl:container xl:mx-auto' : ''}`}>
            <div className="navbar-start">
              <div className="dropdown">
                <label tabIndex={0} className="btn btn-ghost lg:hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                </label>
                <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                  <li><NavLink to="/" className='text-black text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Home</NavLink></li>
                  <li><NavLink to="/about" className='text-black text-[20px] px-[20px] font-normal leading-[65px] uppercase'>About</NavLink></li>
                  <li><NavLink to="/services" className='text-black   relative text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Service</NavLink>
                    <ul className=" shadow bg-white absolute text-left" style={{ borderRadius: '0', zIndex: '9999' }}>
                      <li><NavLink to="/service/research" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Research & Development</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/sample" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Sample Development</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/merchandising" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Merchandising</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/fabric" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Fabric Sourcing</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/production" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Production</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/qaqc" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>QA and QC</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/delivery" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Delivery and Shipment</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/kitting" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Knitting</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/embroidery" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Embroidery</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/garment" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Garment Dyeing & Wash</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/printing" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Printing</NavLink></li>
                      <hr />
                      <li><NavLink to="/service/trimming" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Trimming & Accessories</NavLink></li>
                    </ul>
                  </li>

                  {/* <li><NavLink to="/ComplianceAndEthics" className='text-black text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Compliance & Ethics</NavLink></li> */}
                  <li><NavLink to="/products" className='text-black text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Products</NavLink></li>
                  <li><NavLink to="/contact" className='text-black text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Contact Us</NavLink></li>
                </ul>
              </div>
              <Link to={'/'} className="flex items-center pr-3 border-y-0 border-l-0 normal-case text-xl md:border-r-[1px] border-r-0" style={{ borderRight: '1px solid rgb(255 255 255 / 8%)' }}>
                {/* <img className='max-w-full h-auto w-[70px] mr-5' src="https://i.ibb.co/bvgKQT7/logo.png" color='red' alt="" srcset="" /> */}
                <img className='max-w-full h-auto w-[70px] roundedCircle mr-5 rounded-circle' src="https://i.ibb.co/V20yWKn/logo.png" color='white' alt="" srcset=""/>
                <span className='text-[#cca500] text-[30px] font-semibold uppercase'>
                  N Alam Sourcing BD Ltd
                </span>
              </Link>
            </div>
            <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal px-1">
                <li><NavLink to="/" className='text-white hover:text-[#cca500] text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Home</NavLink></li>
                {/* <li><NavLink to="/about" className='text-white hover:text-[#cca500] text-[20px] px-[20px] font-normal leading-[65px] uppercase'>About</NavLink></li> */}
                <li><NavLink to="/about" className='text-white hover:text-[#cca500]
               text-[20px] px-[20px] font-normal leading-[65px] uppercase'>About</NavLink></li>
                <li><NavLink to="/services" className='text-white  hover:text-[#cca500]  relative text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Services</NavLink>
                  <ul className=" service shadow bg-white absolute text-left" style={{ borderRadius: '0', zIndex: '965416541999' }}>
                    {/* <li><NavLink to="/service/research" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Research & Development</NavLink></li> */}
                    <hr />
                    {/* <li><NavLink to="/service/sample" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Sample Development</NavLink></li> */}
                    <hr />
                    <li><NavLink to="/service/merchandising" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Merchandising</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/fabric" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Fabric Sourcing</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/production" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Production</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/qaqc" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>QA and QC</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/delivery" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Delivery and Shipment</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/kitting" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Knitting</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/embroidery" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Embroidery</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/garment" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Garment Dyeing & Wash</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/printing" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Printing</NavLink></li>
                    <hr />
                    <li><NavLink to="/service/trimming" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14pxpx] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Trimming & Accessories</NavLink></li>
                  </ul>
                </li>

                {/* <li><NavLink to="/ComplianceAndEthics" className='text-white hover:text-[#cca500] text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Compliance & Ethics</NavLink></li> */}
                <li><NavLink to="/Products" className='text-white hover:text-[#cca500] text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Products</NavLink>
                  <ul className=" service shadow bg-white absolute text-left" style={{ borderRadius: '0', zIndex: '9999999' }}>
                    <hr />
                    {/* <li><NavLink htmlFor="my-modal-6" to="/products/knit" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14px] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Knit</NavLink></li> */}
                    <hr />
                    {/* <li><NavLink htmlFor="my-modal-6" to="/products/sweater" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14px] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Sweater</NavLink></li> */}
                    <hr />
                    {/* <li><NavLink htmlFor="my-modal-6" to="/products/woven" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14px] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Woven</NavLink></li> */}
                    {/* <li><NavLink to="/products/HomeWearAndOthers" className='text-[#7b8095] hover:text-[#cca500] hover:bg-transparent text-[14px] leading-[15px] font-normal pt-4 pb-[15px] mx-[25px] roboto'>Home wear & Others</NavLink></li> */}
                  </ul>
                </li>
                <li><NavLink to="/contact" className='text-white hover:text-[#cca500] text-[20px] px-[20px] font-normal leading-[65px] uppercase'>Contact Us</NavLink></li>
                {/* <li tabIndex={0}>
                <a>
                  Parent
                  <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                </a>
                <ul className="p-2">
                  <li><a>Submenu 1</a></li>
                  <li><a>Submenu 2</a></li>
                </ul>
              </li> */}
              </ul>
            </div>
            <div className="navbar-end xl:flex hidden">
              <a  style={{ borderRight: '1px solid rgb(255 255 255 / 8%)' }} href="https://www.linkedin.com/company/n-alam-sourcing-bd-ltd" target="_blank" rel='noreferrer'><i className="fa-brands fa-linkedin-in h-[45px] w-[45px] text-[30px] text-center leading-[45px] mr-[10px] text-[#deba27]"></i></a>
              <a  style={{ borderRight: '1px solid rgb(255 255 255 / 8%)' }} href="/"><i className="fa-brands fa-facebook-f h-[45px] w-[45px] text-[30px] text-center leading-[45px] mr-[10px] text-[#deba27]"></i></a>
              {/* <a href=' '><i className="fa-regular fa-comment-dots h-[45px] w-[45px] text-[30px] ml-[10px] text-center leading-[45px] mr-[10px] text-[#deba27]"></i></a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle" style={{ zIndex: '9999999999999999' }}>
        <div className="modal-box">
          <label htmlFor="my-modal-6" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h3 className="font-bold text-[26px] text-center mb-4">Under Construction!!!</h3>
          <img className='w-full' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZaHZiwjPyGrBA9NgLj1hPUk9XshgID4IyDQ&usqp=CAU" alt="under construction" srcset="under construction" />
          <div className="modal-action">
            <label htmlFor="my-modal-6" className="btn bg-[#cca500] hover:bg-transparent hover:text-[#cca500] border-[#cca500] hover:border-[#cca500] capitalize w-full text-[20px] font-normal">Okay!</label>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Header;