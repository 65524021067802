import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './FooterSlider.css';




const FooterSliders = () => {

  // const [data, setData] = useState();
  // useEffect(() => {
  //   fetch('volunteer.json')
  //     .then(res => res.json())
  //     .then(data => setData(data))
  // }, []);



  return (
    <div className='overflow-hidden bg-[#2da7c9] z-[-1] pt-7 -mb-[60px]'>
      {/* <Slider {...settings}> */}
      <div className="slider">
        <div className="slide-track">
          <div className="slide tooltip" data-tip="WRAP">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064657.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="U.S. Green Building Council">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064640.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Standard 100 by Oeko Tex">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064619.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Sedex">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064555.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Repreve">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064540.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Recycling">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064529.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Accord">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062447.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="ZDHC">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064709.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Australian Organic">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062504.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Bluesign">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062519.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="IOS">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064404.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="ICS">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064346.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Recycled 100">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064455.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Reach Compliant">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101105903.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Responsible Care">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101105914.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Green Seal">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063500.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Fair Wear">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063115.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Fairtrade International">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063043.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="BSCI">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062536.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Global Organic Textile Standard">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063328.png" alt="" srcset="" />
          </div>



          <div className="slide tooltip" data-tip="WRAP">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064657.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="U.S. Green Building Council">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064640.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Standard 100 by Oeko Tex">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064619.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Sedex">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064555.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Repreve">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064540.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Recycling">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064529.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Accord">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062447.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="ZDHC">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064709.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Australian Organic">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062504.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Bluesign">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062519.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="IOS">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064404.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="ICS">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064346.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Recycled 100">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064455.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Reach Compliant">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101105903.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Responsible Care">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101105914.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Green Seal">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063500.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Fair Wear">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063115.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Fairtrade International">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063043.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="BSCI">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062536.png" alt="" srcset="" />
          </div>
          <div className="slide tooltip" data-tip="Global Organic Textile Standard">
            <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101063328.png" alt="" srcset="" />
          </div>
        </div>
        <div className="slide tooltip" data-tip="Accord">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062447.png" alt="" srcset="" />
        </div>
        <div className="slide tooltip" data-tip="AOL">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062504.png" alt="" srcset="" />
        </div>
        <div className="slide tooltip" data-tip="Bluesign">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101062519.png" alt="" srcset="" />
        </div>
        <div className="slide tooltip" data-tip="ISO 9001:2015">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064404.png" alt="" srcset="" />
        </div>
        <div className="slide tooltip" data-tip="ICS">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064346.png" alt="" srcset="" />
        </div>
        <div className="slide tooltip" data-tip="Recycled 100">
          <img src="https://www.nalamsourchingbd.com/image/certification-membership/20230101064455.png" alt="" srcset="" />
        </div>



      </div>
      {/* </Slider> */}
    </div>
  );
};

export default FooterSliders;