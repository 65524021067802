import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/less/autoplay";
// import "swiper/scss/autoplay"


const About = () => {

  useTitle('About Us')
  return (
    <div className=''>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 500,
          disableOnInteraction: false
        }}
        onSlideChange={() => console.log('slide change')}
        className="mySwiper">
        <SwiperSlide>
          <div className={`bg-[url(https://i.ibb.co/GVjWPRN/OIP-1.jpg)] bg-no-repeat bg-center bg-cover block`}  >
            <div className="bg-[#0000009a] bg-opacity-5">
              <div className="text-center py-[75px]">
                <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
                  About Us
                </h2>
                <div>
                  <ul className="flex gap-1 justify-center items-center">
                    <li>
                      <Link to='/' className=''>
                        <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Home</p>
                      </Link>
                    </li>
                    <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                    <li>
                      <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>About Us</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`bg-[url(https://i.ibb.co/XZxzK8m/uni2.png)] bg-no-repeat bg-cover block`}  >
            <div className="bg-[#0000009a] bg-opacity-5">
              <div className="text-center py-[75px]">
                <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
                  About Us
                </h2>
                <div>
                  <ul className="flex gap-1 justify-center items-center">
                    <li>
                      <Link to='/' className=''>
                        <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Home</p>
                      </Link>
                    </li>
                    <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                    <li>
                      <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>About Us</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="bg-[#2da7c9] ">
        <div className="container my-14">
          <div className="text-center mb-14">
            <h3 className='text-[26px] leading-[29px] tracking-[1.2] uppercase font-normal text-[#deba27]'>WHO WE ARE</h3>
            <h2 className='text-[60px] leading-[65px] font-normal text-white mb-[15px]'>
              The Leading Apparel  <span className="font-semibold"> Manufacturer And Exporter</span>
            </h2>
          </div>

          <div data-aos="fade-up" data-aos-duration="2000" className="bg-[#2da7c9] grid lg:grid-cols-2 grid-cols-1 items-center shadow-[0_0px_20px_5px_white]">
            <div className="lg:bg-[url(https://i.ibb.co/XZxzK8m/uni2.png)] bg-cover bg-no-repeat h-full w-full block">
              <img className='w-full lg:hidden block' src="https://i.ibb.co/XZxzK8m/uni2.png" alt="" />
            </div>
            <div className="py-[75px] px-[50px]">
              <p className='text-[16px] text-white font-normal leading-[26px]'>
                N ALAM SOURCING (BD) LTD. is a global apparel sourcing agent in Dhaka, Bangladesh. 
                It was established in the year 2021 with theinitiative of a number of well experienced 
                professionals in thegarments export sector. After more than two years of its steadyjourney
                it has reached to a position of a reputed buying house in thecountry. The company has developed
                strong sourcing network in theproduction of garments of all kinds – knit, woven & sweater as wellas sportswear. 
                We are quite confident to do any kind of apparelproducts within agreed time frame maintaining quality 
                requirementsand specifications . We are involved inmanufacturing and sourcing of all kind of RMG items, namely Men’s,
                Ladies, boys, girls, kids, Unisex items such as Pants, shirts, dress, sweaters, Socks, pullover, cardigans, jumper, vests, t-shirt, polo shirt,
                hoodie, sweat-shirt, jogging suits, etc.
                As a leading company in the apparel industry, it's important to keep up with the latest trends and
                market demands. It may also be beneficial to focus on sustainable and ethical production practices to
                attract more socially conscious customers.
                Overall, it sounds like N Alam Sourcing BdLimited has established itself as a reputable and successful
                company in the apparel industry and I wish you continued success in the future.              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" className="grid lg:grid-cols-2 grid-cols-1 items-center  shadow-[0_0px_20px_5px_white]">
            <img className='w-full lg:hidden block' src="https://i.ibb.co/0Q9YCG9/Whats-App-Image-2023-02-23-at-11-47-07-PM.jpg" alt="" />
            <div className="py-[75px] px-[50px]">
              <p className='text-[16px] text-white font-normal leading-[26px]'>
                Our robust team of professional experts consists of experienced and dynamic individuals. Our
                unassailable lead ensures the service to be immaculate, all the way from product development
                to the final shipment. It has always been our solemn vow to abide by all the local and
                international compliances that apply to our line of work. To ensure the best quality products, our
                sourcing team is comprised of extensively experienced sourcing professionals with extensive
                market knowledge. We employ veteran quality control experts with years of experience, who
                make sure the client gets exactly what they asked for. For the shipment procedures, we work
                with the best C&F agencies in the country to guarantee smooth sailing to the customer’s desired
                port. From the very beginning, we have established ourselves as a one stop product sourcing,
                design, production, quality assurance and sustainable supply chain solution to all of our
                international clients.
              </p>
            </div>
            <div className="lg:bg-[url(https://i.ibb.co/0Q9YCG9/Whats-App-Image-2023-02-23-at-11-47-07-PM.jpg)] bg-cover bg-no-repeat h-full w-full block">
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" className="grid lg:grid-cols-2 grid-cols-1 items-center  shadow-[0_0px_20px_5px_white]">
            <div className="lg:bg-[url(https://i.ibb.co/jVr6C11/R-2.jpg)] bg-cover bg-no-repeat h-full w-full block">
              <img className='w-full lg:hidden block' src="https://i.ibb.co/jVr6C11/R-2.jpg" alt="" />
            </div>
            <div className="py-[75px] px-[50px]">
              <p className='text-[16px] text-white font-normal leading-[26px]'>
                As Most of the prominent brands are working with N Alam Sourcing Bd so from the beginning we are
                following all compliance issues & international certifications for Sustainable Development &
                Productions. All of our Factories & production places are 100% compliance. Moreover, we use
                sustainable washing plants with all certifications (DETOX, BEPI, BSCI, GOTS Etc). With the
                “Green factory” initiative in mind we thrive to work with factories that abide by this code and put
                workplace safety above all. We believe in a society where every stakeholder is satisfied and gets
                the output they deserve, seamlessly blending the hard work with peace of mind. To our existing
                clientele we are an organization of complete faith and trust and with this, we are destined to
                become the biggest buying house, Manufacturer & Exporter in the Bangladeshi RMG sector.
              </p>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 mt-14">
            <div className="px-[15px] py-14">
              <h2 className='text-[50px] text-white leading-[64px] font-medium capitalize mb-4 text-center'>
                Our Mission And Belief
              </h2>
              <div className="bg-[url(https://i.ibb.co/q9jPdRC/World-Map.jpg)] bg-no-repeat bg-left-top  min-h-[500px] lg:-ml-[100px] -ml-[50px]"
                style={{ backgroundSize: "100% 100%" }}></div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div className="flex items-end px-3">
                <div className="">
                  <div className="mb-7">
                    <div data-aos="fade-right" data-aos-duration="2000" className="bg-[#2da7c9] pr-[15px] pl-[30px] py-[30px] shadow-[inset_0_0px_10px_1px_white]">
                      <div>
                        <div className="max-w-[120px]">
                          <img src='https://i.ibb.co/Q6YnPMF/mission-icon-01.png' alt='Our Mission' className='text-[#FFFFFF] w-full mb-[15px] text-[37px] block' />
                        </div>
                      </div>
                      <h2 className='text-[42px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF]'>
                        Our Mission
                      </h2>
                      <p className='text-white'>
                        Our mission is to create an OSASP (One Stop All Solution Platform) service
                        for getting suitable goods to the right person at the right time
                      </p>
                    </div>
                  </div>
                  <div className="mb-7">
                    <div data-aos="fade-right" data-aos-duration="3000" className="bg-[#2da7c9] pr-[15px] pl-[30px] py-[30px] w-auto shadow-[inset_0_0px_10px_1px_white]">
                      <div>
                        <div className="max-w-[120px]">
                          <img src='https://i.ibb.co/nPC0VmX/vision-icon-01.png' alt='Vision' className='text-[#FFFFFF] mb-[15px] text-[37px] block w-full' />
                        </div>
                      </div>
                      <h2 className='text-[42px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF]'>
                        Our Diligence
                      </h2>
                      <p className='text-white'>
                        Our Diligence is to constantly exceed our client's expectations by delivering highquality products on time and with the highest level of customer satisfaction.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
              <div className="flex items-start px-3">
                <div className="">
                  <div className="mb-7">
                    <div data-aos="fade-left" data-aos-duration="2000" className="bg-[#2da7c9] pr-[15px] pl-[30px] py-[30px] shadow-[inset_0_0px_10px_1px_white]">
                      <div>
                        <div className="max-w-[120px]">
                          <img src='https://i.ibb.co/7bMMrdJ/spirit-icon-01.png' alt='Our Spirit' className='text-[#FFFFFF] mb-[15px] text-[37px] block' />
                        </div>
                      </div>
                      <h2 className='text-[42px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF]'>
                        Our Spirit
                      </h2>
                      <p className='text-white'>
                        Professionalism is Our Spirit
                      </p>
                    </div>
                  </div>
                  <div className="mb-7 w-auto">
                    <div data-aos="fade-left" data-aos-duration="3000" className="bg-[#2da7c9] pr-[15px] pl-[30px] py-[30px] shadow-[inset_0_0px_10px_1px_white]">
                      <div>
                        <div className="max-w-[120px]">
                          <img src='https://i.ibb.co/Pr35Zsf/values-icon-01.png' alt='Values' className='text-[#FFFFFF] mb-[15px] text-[37px] block' />
                        </div>
                      </div>
                      <h2 className='text-[42px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF]'>
                        Values
                      </h2>
                      <p className='text-white'>
                        We aspire to consistently surpass our people's and customers' expectations,
                        operate with integrity regardless of the repercussions, accept full
                        responsibility for our actions, and collaborate to achieve our goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;