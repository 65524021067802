import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const porductsCardData = [
  {
    heading: 'Woven',
    image: 'https://www.nalamsourchingbd.com/image/products/woven.jpg',
    productLink: '/products/woven'
  },
  {
    heading: 'Knit',
    image: 'https://www.nalamsourchingbd.com/image/products/knit.jpg',
    productLink: '/products/knit'
  },
  {
    heading: 'Sweater',
    image: 'https://www.nalamsourchingbd.com/image/products/sweater.jpg',
    productLink: '/products/sweater'
  },
  {
    heading: 'Homewear And Others',
    image: 'https://www.nalamsourchingbd.com/image/products/homewear.jpg',
    productLink: '/products/HomeWearAndOthers'
  }
]

const Products = () => {
  return (
    <div className="">
      <div className="bg-[url(https://i.ibb.co/GVjWPRN/OIP-1.jpg)] bg-no-repeat bg-cover block">
        <div className="bg-[#0000009a] bg-opacity-5">
          <div className="text-center py-[75px]">
            <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
              Products
            </h2>
            <div>
              <ul className="flex gap-1 justify-center items-center">
                <li>
                  <Link to='/' className=''>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Home</p>
                  </Link>
                </li>
                <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                <li>
                  <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Products</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='my-24 container'>
        <div className='text-center mb-14'>
          <h3 className='text-[#deba27] text-[26px] font-normal leading-[29px] tracking-[1.2px] text-center'>OUR PRODUCTS</h3>
          <h2 className='text-[60px] text-[#FFF] font-normal leading-[65px]'>N Alam Sourcing Bd Limited Ensures The Best Products</h2>
        </div>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5'>
          {
            porductsCardData.map((singleCard, idx) => <div data-aos="fade-up" data-aos-duration="2000" key={idx} className='group p-4 service-card-shadow card-items-style cursor-pointer'>
              <div className='max-h-[315px] overflow-hidden relative'>
                <div className='service-thumbnail'>
                  <img className='h-full transition-all duration-500' src={singleCard.image} alt="" />
                </div>
              </div>
              <h3 className='text-[26px] leading-8 flex justify-between pt-4 pb-2'>
                <Link to={singleCard?.productLink} className="text-[#FFF] group-hover:text-[#deba27] transition-all duration-300">{singleCard.heading}</Link>
                <span className=''>
                  <i className="fa-solid fa-arrow-up-long icon-style text-[#FFF] group-hover:text-[#deba27] text-[20px] transition-all duration-500"></i>
                </span></h3>
            </div>)
          }
        </div>
      </div>
    </div>
  );
};

export default Products;