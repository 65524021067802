import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ComplianceAndEthicsDetails = () => {
  return (
    <div>
      <div className="bg-[url(https://i.ibb.co/GVjWPRN/OIP-1.jpg)] bg-no-repeat bg-cover block">
        <div className="bg-[#0000009a] bg-opacity-5">
          <div className="text-center py-[75px]">
            <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
              Compliance & Ethics
            </h2>
            <div>
              <ul className="flex gap-1 justify-center items-center">
                <li>
                  <Link to='/' className=''>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Home</p>
                  </Link>
                </li>
                <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                <li>
                  <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Compliance & Ethics</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-14">
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:flex">
          <div className="lg:w-8/12 w-full px-3">
            <div className="">
              <img src="https://www.nalamsourchingbd.com/image/compliance-ethics/compliance.jpg" alt="" srcset="" />
            </div>
          </div>
          <div className="lg:w-4/12 w-full px-3">
            <div className="">
              <div className="bg-[#deba27] py-5 px-4">
                <h2 className='text-[30px] text-[#FFF] text-center font-normal'>Compliance & Ethics</h2>
              </div>
              <div className="bg-[#f7f7f7] py-5 px-5">
                <div className="bg-white">
                  <div className="flex items-center border-b-[1px] px-4 py-6">
                    <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                      <i class="fa-solid fa-check text-[16px] text-white"></i>
                    </div>
                    <div className='pl-4'>
                      <span className='text-[22px] leading-[30px] font-medium capitalize'>Compliance Vision</span>
                    </div>
                  </div>
                  <div className="flex items-center border-b-[1px] px-4 py-6">
                    <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                      <i class="fa-solid fa-check text-[16px] text-white"></i>
                    </div>
                    <div className='pl-4'>
                      <span className='text-[22px] leading-[30px] font-medium capitalize'>Zero Tolerance Policy</span>
                    </div>
                  </div>
                  <div className="flex items-center border-b-[1px] px-4 py-6">
                    <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                      <i class="fa-solid fa-check text-[16px] text-white"></i>
                    </div>
                    <div className='pl-4'>
                      <span className='text-[22px] leading-[30px] font-medium capitalize'>Code Of Conduct</span>
                    </div>
                  </div>
                  <div className="flex items-center border-b-[1px] px-4 py-6">
                    <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                      <i class="fa-solid fa-check text-[16px] text-white"></i>
                    </div>
                    <div className='pl-4'>
                      <span className='text-[22px] leading-[30px] font-medium capitalize'>CSR</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
          </div>
        </div>
        <div className="px-3 pt-5">
          <p className='mb-4 font-normal text-[16px] leading-[26px] text-[#222429]'>We Do Obey All Legal & Social Compliances Guided by Laws and Regulatory Bodies as Per Local Government Order & Global Requirements. Some of Our Major
            <br />
            Compliance Issues Are:</p>

          <div data-aos="fade-up" data-aos-duration="2000" className="bg-[#f7f7f7] pt-11 pb-5 px-7 my-14">
            <div className="bg-[#deba27] py-5 px-4 mb-[30px]">
              <h2 className='text-[30px] text-[#FFF] text-center font-normal'>Compliance Vision</h2>
            </div>
            <div className="pt-11 pb-5 px-7">
              <div className="">
                <h3 className='text-[30px] leading-[35px] mb-4 font-medium text-[#222429] capitalize'>Social Compliance</h3>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                <div className="mx-3">
                  <div className="py-5 pl-6 pr-5 bg-white">
                    <p>
                      To uphold the reputation of our Customers, Business Partners & our organization by ensuring ethics, integrity and technical expertise are uncompromisingly practiced in our audit processes.
                    </p>
                    <hr className='my-[14px]' />
                    <p>
                      To uphold the reputation of our Customers, Business Partners & our organization by ensuring ethics, integrity and technical expertise are uncompromisingly practiced in our audit processes.
                    </p>
                    <hr className='my-[14px]' />
                    <p>
                      To uphold the reputation of our Customers, Business Partners & our organization by ensuring ethics, integrity and technical expertise are uncompromisingly practiced in our audit processes.
                    </p>
                  </div>
                </div>
                <div className="mx-3">
                  <img className='w-full h-full' src="https://www.nalamsourchingbd.com/image/compliance-ethics/4.jpg" alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="pt-11 pb-5 px-7">
              <div className="flex">
                <div className="lg:w-4/12 w-1/2">
                  <h2 className='text-[30px] font-medium mb-4'>Employees’ COC Covers</h2>
                  <ol className='list-decimal	pl-6 my-4'>
                    <li><p>Bribery</p></li>
                    <li><p>Disclosure of confidential documents</p></li>
                    <li><p>Misappropriation of assets</p></li>
                    <li><p>Falsification of records</p></li>
                    <li><p>Sexual harassment</p></li>
                    <li><p>Activities of conflicting business interest</p></li>
                    <li><p>Violation of sourcing ZTV(zero tolerance violation) code.</p></li>
                  </ol>
                </div>
                <div className="lg:w-8/12 w-1/2">
                  <div className="">
                    <h2 className='text-[30px] font-medium mb-4'>Employees’ COC Covers</h2>
                    <ol className='list-decimal	pl-6 my-4 grid lg:grid-cols-2 grid-cols-1'>
                      <li><p>Child labor</p></li>
                      <li><p>Forced labor</p></li>
                      <li><p>Disciplinary practices</p></li>
                      <li><p>Harassment & Abuse</p></li>
                      <li><p>Legal requirements</p></li>
                      <li><p>Ethical standards</p></li>
                      <li><p>Working hours</p></li>
                      <li><p>Wages & Benefits</p></li>
                      <li><p>Freedom of Association</p></li>
                      <li><p>Discrimination</p></li>
                      <li><p>Unauthorized subcontracting</p></li>
                      <li><p>Building and fire safety</p></li>
                      <li><p>Health, Safety</p></li>
                      <li><p>Environment</p></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="2000" className="bg-[#f7f7f7] my-14">
            <div className="bg-[#deba27] py-5 px-4 mb-[30px]">
              <h2 className='text-[30px] text-[#FFF] text-center font-normal'>Zero Tolerance Policy</h2>
            </div>
            <div className="pt-11 pb-5 px-7">
              <div className="">
                <h3 className='text-[30px] leading-[35px] mb-4 font-medium text-[#222429] capitalize'>Compliance Issues To Be Categorized As ZTV</h3>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                <div className="mx-3">
                  <div className="py-5 pl-6 pr-5 bg-white">
                    <p>
                      Child labor
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Forced labor
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Discrimination
                    </p>
                    <p>
                      Harassment and Abuse
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Unauthorized subcontracting including Tier 2 operations regardless of brands
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Shared building unless approved by Head of Compliance (any other factory owned by different owner located in the same building) or factory located in building which has shops/markets
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Factory building approved for residential purposes
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      Any unethical practice, such as bribery in the form of cash or kind to facilitate any process
                    </p>
                  </div>
                </div>
                <div className="mx-3">
                  <img className='w-full h-full pb-[15px]' src="https://www.nalamsourchingbd.com/image/compliance-ethics/no-child-labor.jpg" alt="" srcset="" />
                  <img className='w-full h-full' src="https://www.nalamsourchingbd.com/image/compliance-ethics/harassment-and-abuse.jpg" alt="" srcset="" />
                </div>
              </div>
            </div>

          </div>
          <div data-aos="fade-up" data-aos-duration="2000" className="bg-[#f7f7f7] my-14">
            <div className="bg-[#deba27] py-5 px-4 mb-[30px]">
              <h2 className='text-[30px] text-[#FFF] text-center font-normal'>Code Of Conduct</h2>
            </div>
            <div className="pt-11 pb-5 px-7">
              <h3 className='text-[30px] leading-[35px] mb-4 font-medium text-[#222429] capitalize'>N Alam Sourcing Bd  Ltd Code Of Conduct For Manufacturing Unit</h3>
              <p className='mb-4'>
                Our code is derived from the values and standards set by our customers. More particularly the Declaration of Human Rights and many of the ILO core conventions and local laws. It is our earnest endeavor to meet all the aspects of our buyers’ code of conduct. We only work with factories which are approved by our buyers.
              </p>
              <div className="mt-4">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center my-3">
                  <div className="mr-3">
                    <img className='w-full h-full' src="https://www.nalamsourchingbd.com/image/compliance-ethics/2.jpg" alt="" srcset="" />
                  </div>
                  <div className="ml-3">
                    <img className='w-full h-full' src="https://www.nalamsourchingbd.com/image/compliance-ethics/3.jpg" alt="" srcset="" />
                  </div>
                </div>
                <div className="my-3">
                  <div className="py-5 pl-6 pr-5 bg-white">
                    <p>
                      <span className='font-semibold'>Approved Factory:</span> It will be our earnest endeavor to meet all the aspects of our buyers’ code of conduct. We will only work with such factories which are approved by our buyers.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Child labor:</span> Use of Child labor is not tolerated by our code. We do not work with such business partners who employ workers lesser than 15 years of age. Furthermore, no worker shall be younger than the mandatory school going age in the respective countries of operation. If the local law stipulates a higher minimum age than that of 15 years, then a more stringent limit is applicable.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Forced labor:</span> We do not work with any factory or organization which engages in forced or bonded labor.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Disciplinary practices:</span> We expect all our business partners to establish a clear disciplinary action procedure in line with the local law. We do not work with factories consisting of employees, who use abusive language, or practice corporal punishment, in the form of mental or physical abuse or any other coercive practices in any form against the workers.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Harassment & Abuse:</span> We do not work with any factory or organization who engages in any kind of harassment and abuse. It is strongly prohibited and contradicts the buyer Code of conduct, as per our business ethics as well as our local law
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Legal requirements:</span> We expect all our business partners to comply with the local laws applicable to the conduct of their business.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Ethical standards:</span> We make sure to identify and work with such organizations whose ethical standards are not divergent from ours.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Working hours:</span> We prefer to work with business partners who try and meet the 60 hour weekly limit. Whenever the regular work hour limit is exceeded, we expect the workers to be compensated as per the local law for the additional overtime hours. We accept flexibility in scheduling work hours, however we will not use business partners, who on a regular and systematic basis make the employees work more than the 60 hour weekly limit. Also, workers should be given one day off in seven days.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Wages and Benefits:</span> We only work with such business partners who compensate their workers as per the prevailing law and provide all benefits legally due to them.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Freedom of Association:</span> We respect the rights of workers to join an association of their choice and their right to Collective Bargaining. We only work with such business partners who share this same belief and they should ensure that workers who participate or associate with such movements are not discriminated against. No Punitive action should be taken against such workers for being a part of such association or movement as long as they don’t violate any of the local laws.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Discrimination:</span> While being cognizant of cultural, religious and other differences, we firmly believe that workers should be given an opportunity to work, based on their skills only. Caste, Creed, Race etc: shall not be a part of the process used to decide employability.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Unauthorized Subcontract:</span> Unauthorized subcontracting is considered a Zero Tolerance Violation. No vendor shall subcontract any aspect of our production without prior information to and approval from our company. Any violation will result in delisting of such factories.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Building and fire safety:</span> We expect firmly that all our business partners ensure building and fire safety as per the local Law and the buyer’s requirement.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Health & safety:</span> We engage only with such factories who provide their workers a safe and healthy work environment.
                    </p>
                    <hr className='my-[24px]' />
                    <p>
                      <span className='font-semibold'>Environment:</span> We want all our business partners to ensure that their work process does not affect the environment adversely in any way. It is expected of all our business partners to meet the legal requirement on all environmental aspects and continuously strive to go beyond just meeting the law.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div data-aos="fade-up" data-aos-duration="2000" className="bg-[#f7f7f7] pt-11 pb-5 px-7 my-14">
            <div className="bg-[#deba27] py-5 px-4 mb-[30px]">
              <h2 className='text-[30px] text-[#FFF] text-center font-normal'>CSR</h2>
            </div>
            <div className="pt-11 pb-5 px-7">
              <div className="">
                <h3 className='text-[30px] leading-[35px] mb-4 font-medium text-[#222429] capitalize'>CSR (Corporate Social Responsibility)</h3>
                <p className='mb-4'>
                N Alam Sourcing Bd  Limited prefers and works only with factories who take their social responsibility seriously and puts in effective measures to ensure the betterment of the community and environment around them. This not only boosts our morality but also gives our clients the opportunity to feel that something of greater good comes out of their business with us above the monetary transactions.
                </p>
                <p className='mb-4'>
                  It is in our code to make sure our business partners are all warry of the wellbeing of their workers and the environment. Our compliance factories have in-house daycare centers, emergency first aid professionals and many other social windows to ensure the workers peace of mind. They are also well equipped with waste disposal equipment and methods to ensure minimal adverse effects to the environment.
                </p>
                <p className='mb-4'>
                  Besides, we are proud to have developed and supported exceptional charitable initiatives across Bangladesh. And N Alam Sourcing Bd  limited participating in all, from the beginning. Ensuring proper Education for underprivileged and orphaned children, Funds for rural area clinics etc.
                </p>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                <div className="mx-3">
                  <div className="py-5 pl-6 pr-5 bg-white">
                    <p>
                      <span className='font-semibold'>Ensuring Education for Kids:</span>N Alam Sourcing Bd  Limited feels that since we are at one with the world it is our responsibility to improve the world. The mission is to provide underprivileged and orphaned children a quality education which will give them the skills they need to provide for themselves and their families in the future – thereby breaking the vicious cycle of poverty.
                    </p>
                    <hr className='my-[44px]' />
                    <p>
                      <span className='font-semibold'>Funds for Clinic:</span> We are allocating some portion of our funds in clinics which are located in Rural area. We donate to these clinics to provide free medical care to impoverished people. These health clinics provide free consultancy, treatment and homeopathic medicines to the poorest people in these rural areas.
                    </p>
                  </div>
                </div>
                <div className="mx-3">
                  <img className='w-full h-full' src="https://www.nalamsourchingbd.com/image/compliance-ethics/1.jpg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceAndEthicsDetails;